<template>
  <div>
    <b-row>
      <b-col class="col-md-6 col-12">
        <Breadcrumbs main="" title="Services" class="showBreadcrumbs" />
        <!-- <Breadcrumbs main="" title="Services" :display="isBreadView" /> -->
      </b-col>
    </b-row>
    <b-row>
      <!-- Filter starts here -->
      <b-row class="justify-content-between width-90">
        <b-col class="col-md-3 col-12 date-range-custom">
          <b-form-group label="Date Range">
            <date-range-picker
              style="display: inline"
              ref="picker"
              opens="right"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="true"
              :autoApply="false"
              v-model="dateRange"
            
              :linkedCalendars="false"
              :ranges="customDateRanges"
              :max-date="new Date()"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                
              </template>
            </date-range-picker>
          </b-form-group>
        </b-col>
        <b-col class="col-md-3 col-12">
          <b-form-group label="Select Ad Type">
            <multiselect
              :options="allAdtype"
              label="name"
              v-model="selectedAdtype"
              placeholder="Select Ad Type"
              :multiple="false"
              :close-on-select="true"
              track-by="_id"
            ></multiselect>
          </b-form-group>
        </b-col>
        <b-col class="col-md-3 col-12">
          <b-form-group label="Select Category">
            <multiselect
              :options="allCategory"
              label="name"
              v-model="selectedCategory"
              placeholder="Select Category"
              :multiple="false"
              :close-on-select="true"
              track-by="id"
            ></multiselect>
          </b-form-group>
        </b-col>
        <b-col class="col-md-3 col-12">
          <b-form-group label="Select Provider">
            <multiselect
              :options="providerlist"
              label="name"
              v-model="selectedProvider"
              placeholder="Select Provider"
              :multiple="false"
              :close-on-select="true"
              track-by="_id"
            ></multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-between width-90">
        <b-col class="col-md-4 col-12 mt-1 date-range-custom">
          <b-form-group label="Search by Title">
            <b-input-group class="">
              <b-input-group-prepend>
                <span class="input-group-text search-bg"
                  ><i class="icofont icofont-search-alt-2"></i
                ></span>
              </b-input-group-prepend>
              <b-form-input
                class="border-left-0"
                v-model="selectedTitle"
                placeholder="Search by Title "
                type="search"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          class="col-md-4 col-12 text-right d-flex justify-content-end align-items-center mt-2"
        >
          <button
            class="btn custom-btn"
            style="margin-top: 0.7vh"
            @click="filterData"
          >
            Search
          </button>
          <button
            class="btn custom-btn ml-2"
            style="margin-top: 0.7vh"
            @click="init()"
          >
            Reset
          </button>
        </b-col>
      </b-row>
      
      <!-- Filter ends here -->
      <b-col xl="12" md="">
        <b-card title="" type="gradient-red" sub-title="" class="mb-4">
          <b-row class="align-items-start">
            <b-col class="col-md-6 col-12">
              <span class="custome-title">Service Info</span>
            </b-col>
            <b-col
              class="col-md-6 col-12 d-flex justify-content-end align-items-start"
            >
              <b-form-group
                label-cols="7"
                label="Per page"
                class="col-6 md-0 custom-font-18 text-right"
              >
                <b-form-select
                  class="border custom-font-24"
                  v-model="perPage"
                  :options="pageOptions"
                  @change="handlePerPageChange"
                >
                </b-form-select>
              </b-form-group>
              <button class="btn custom-btn" @click="addService()">
                Add Service
              </button>
            </b-col>
          </b-row>
          <div class="col-md-12" v-if="loading">
            <h6 class="sub-title mb-0 text-center">Loading...</h6>
            <div class="loader-box">
              <div class="loader-30"></div>
            </div>
          </div>
          <div v-else class="table-responsive datatable-vue">
            <b-table
              class="text-left custom-font-16"
              :fields="serviceFields"
              striped
              hover
              :items="serviceData"
              stacked="md"
              :filter="filter"
              :per-page="perPage"
              @filtered="onFiltered"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template #cell(_id)="row">
                <span class="cust_text">{{ row.item._id }}</span>
              </template>
              <template #cell(title)="row">
                <span >{{ row.item.title && row.item.title.length > 25 ? row.item.title.slice(0,25)+"..." : row.item.title }}</span>
              </template>
              <template #cell(status)="row">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      :checked="row.item.status"
                      @change="changeStatus(row.item)"
                    />
                    <span
                      class="switch-state"
                      :class="row.item.status ? 'bg-success' : 'bg-danger'"
                    ></span>
                  </label>
                </div>
              </template>
              <template #cell(spid)="row">
                <span
                  class="cust_text"
                  v-if="providerlist.find((e) => e._id == row.item.spid)"
                >
                  {{ providerlist.find((e) => e._id == row.item.spid).name }}
                </span>
              </template>
              <template #cell(adtype)="row">
                <span v-if="adTypeArray.find((e) => e.id == row.item.adtype)">
                  {{ adTypeArray.find((e) => e.id == row.item.adtype).name }}
                </span>
              </template>
              <template #cell(category)="row">
                <span
                  v-if="categoryArray.find((e) => e.id == row.item.category)"
                >
                  {{
                    categoryArray.find((e) => e.id == row.item.category).name
                  }}
                </span>
              </template>
              
              <template #cell(actions)="row">
                <button
                  size="sm"
                  @click="openSDetail(row.item)"
                  class="btn text-blue btn-xs mr-1"
                >
                  <feather type="edit"></feather>
                </button>
                <button
                  size="sm"
                  @click="deleteSDetail(row.item)"
                  class="btn text-danger btn-xs"
                >
                  <feather type="trash-2"></feather>
                </button>
              </template>
            </b-table>
          </div>
          <b-col class="mt-3 p-0">
            <div class="pagination">
                  <a 
                    v-show="currentPage > 1" 
                    @click="changePage(currentPage - 1)"
                    :disabled="loading"
                  >« Back</a>
                  <a class="active">{{ currentPage }}</a>
                  <a 
                    v-show="currentPage < totalPages" 
                    @click="changePage(currentPage + 1)"
                    :disabled="loading"
                  >Next »</a>
              </div>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
    <!-- Service provider Modal -->
    <b-modal
      v-model="serviceModal"
      id="openSPDetail"
      title="Service Provider"
      class="theme-modal"
      @hidden="closeModal"
      size="xl"
      no-close-on-backdrop
      hide-footer
    >
      <serdetail
        :sddetail="sddetail"
        :serviceModal="serviceModal"
        :isEdit="isEdit"
        @closeSdDetail="closeModal"
      ></serdetail>
      
    </b-modal>
    <b-container fluid class="mt--7">
      <b-row class="justify-content-center">
        <b-col lg="12"> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import ServiceInfoService from "../../../../services/ServiceInfo";
import ServiceProviderServices from "../../../../services/ServiceProviderServices";
import serdetail from "./AddServiceInfo.vue";
import config from "../../../../services/globalLit";
import Multiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  components: {
    serdetail,
    Multiselect,
    DateRangePicker,
  },
  watch: {
    dateRange: {
      handler() {
        this.filterData();
      },
      deep: true,
    },
    selectedAdtype: {
      handler() {
        this.filterData();
      },
      deep: true,
    },
    selectedCategory: {
      handler() {
        this.filterData();
      },
      deep: true,
    },
    // selectedTitle: {
    //   handler() {
    //     this.filterData();
    //   },
    //   deep: true,
    // },
    selectedProvider: {
      handler() {
        this.filterData();
      },
      deep: true,
    },
  },
  mounted() {
    this.userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
    // if (this.userinfo.role != "manager") {
    //   this.$router.push("/auth/login");
    // }
    this.init();
  },
  data() {
    const today = new Date();
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const endOfToday = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const startOfYesterday = new Date(yesterday.setHours(0, 0, 0, 0));
    const endOfYesterday = new Date(yesterday.setHours(23, 59, 59, 999));
    return {
      dateRange: {
        startDate: startOfToday,
        endDate: endOfToday
      },
      customDateRanges: {
        'Today': [startOfToday, endOfToday],
        'Yesterday': [startOfYesterday, endOfYesterday],
        'Last 7 Days': [
          new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000),
          endOfToday,
        ],
        'Last 30 Days': [
          new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000),
          endOfToday,
        ],
        'This Week': [
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay(), 0, 0, 0),
          endOfToday,
        ],
        'Last Week': [
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7, 0, 0, 0),
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 1, 23, 59, 59),
        ],
        'Last 3 Weeks': [
          new Date(today.getTime() - 21 * 24 * 60 * 60 * 1000),
          endOfToday,
        ],
        'This Month': [
          new Date(today.getFullYear(), today.getMonth(), 1),
          endOfToday,
        ],
        'Last Month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59),
        ],
        'Last 3 Months': [
          new Date(today.getFullYear(), today.getMonth() - 3, 1),
          endOfToday,
        ],
      },
      serviceModal: false,
      adTypeArray: config.adTypeArray,
      categoryArray: config.categorylist,
      providerlist: [],
      serviceFields: [
        {
          key: "_id",
          label: "ID",
          sortable: true,
        },
        {
          key: "title",
          label: "Title",
          sortable: true,
        },
        {
          key: "spid",
          label: "Provider",
          sortable: true,
        },
        {
          key: "adtype",
          label: "Ad Type",
        },
        {
          key: "category",
          label: "Category",
          sortable: true,
        },
        // {
        //   key: "adUrl",
        //   label: "Ad Url",
        // },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      serviceData: [],
      userinfo: {},
      sddetail: {},
      isEdit: false,
      loading: false,
      totalRows: 0,
      totalPages: 0,
      filter: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [1,2,3,4,5,20, 50, 100, 200],
      sortBy: "_id",
      sortDesc: true,
      sortDirection: "desc",
      allAdtype: [],
      selectedAdtype: null,
      allCategory: [],
      selectedCategory: null,
      selectedTitle: "",
      selectedProvider: "",
    };
  },
  methods: {
    filterData() {

      let payload = {
        page: this.currentPage,
        limit: this.perPage // Changed from pageSize to limit to match common backend convention
      };

      if (this.selectedAdtype) {
        payload.adtype = this.selectedAdtype._id;
      }
      if (this.selectedCategory) {
        payload.category = this.selectedCategory.id;
      }
      if (this.selectedTitle) {
        payload.title = this.selectedTitle;
      }
      if (this.selectedProvider) {
        payload.spid = this.selectedProvider._id;
      }
      if (this.dateRange.startDate && this.dateRange.endDate) {
        const startDate = new Date(this.dateRange.startDate);
        const endDate = new Date(this.dateRange.endDate);
        // Adjust for timezone offset
        const startDateISOString = new Date(
          startDate.getTime() - startDate.getTimezoneOffset() * 60000
        ).toISOString();
        const endDateISOString = new Date(
          endDate.getTime() - endDate.getTimezoneOffset() * 60000
        ).toISOString();
        payload.sdate = startDateISOString; // Set formatted start date
        payload.edate = endDateISOString; // Set formatted end date
      }
      console.log("payload => ", payload);
      const response = this.getServiceInfo(payload);
      console.log("response => ", response);
    },


    async changePage(newPage) {
      this.currentPage = newPage;
      await this.filterData();
    },

    handlePerPageChange(newPerPage) {
      this.perPage = newPerPage;
      this.currentPage = 1;
      this.filterData();
    },




    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    CopyShareUrl(urls) {
      config.CopyShareUrl(urls);
      this.$toasted.show("Copied", {
        theme: "outline",
        position: "bottom-center",
        type: "success",
        duration: 2000,
      });
    },
    closeModal() {
      this.serviceModal = false;
      this.init();
      this.isEdit = false;
    },
    openSDetail(data) {
      this.isEdit = true;
      this.sddetail = data;
      this.serviceModal = true;
    },
    addService() {
      this.isEdit = false;
      this.sddetail = {};
      this.serviceModal = true;
    },
    async deleteSDetail(data) {
      let payload = {
        sid: data._id,
      };
      if (confirm("Are you sure you want to delete")) {
        let response = await ServiceInfoService.remove(payload);
        this.init();
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
        });
      }
    },
    async changeStatus(data) {
      if (data.status == false) {
        data.status = true;
      } else {
        data.status = false;
      }
      let payload = {
        sid: data._id,
        changeBody: {
          status: data.status,
        },
      };
      let response = await ServiceInfoService.update(payload);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(data.status=== false ? "Service Deactivated" : "Service Activated"  || response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 2000,
      });
    },
    
    async getServiceInfo(payload) {
      this.loading = true;
      let [response, spData] = await Promise.all([
        ServiceInfoService.get(payload),
        ServiceProviderServices.get(),
      ]);
      if (spData.result) {
        // Only keep active providers
        this.providerlist = spData.data.filter(provider => provider.status === true).reverse();
        console.log("spdata", spData);
        this.allAdtype = [
          { _id: "1", name: "Banner" },
          { _id: "2", name: "In App" },
        ];
        this.allCategory = config.categorylist;
        console.log("categorylist", config.categorylist);
      }
      if (response.result) {
        // Filter serviceData to only include services from active providers
        const activeProviderIds = this.providerlist.map(provider => provider._id);
        this.serviceData = response.data
          .reverse()
          .filter(item => 
            item.sp_name !== "" && 
            activeProviderIds.includes(item.spid) // Ensure provider is active
          );
        console.log("serviceData", this.serviceData);
        this.totalRows = this.serviceData.length;
        this.totalRows = response.pagination?.total || response.data.length;
          this.totalPages = response.pagination?.totalPages || 
                          Math.ceil(this.totalRows / this.perPage);
          this.currentPage = response.pagination?.currentPage || this.currentPage;
      }
      this.loading = false;
    },
    ChangedDate() {
      let sdate = new Date(
        this.dateRange.startDate.getTime() -
          this.dateRange.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      let edate = new Date(
        this.dateRange.endDate.getTime() -
          this.dateRange.endDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.dateRange.startDate = sdate;
      this.dateRange.endDate = edate;
      // this.filterData(this.monitor_id, this.dateRange)
    },
    changeDateFormate(d) {
      // return moment.utc(d).format("D/M/YYYY");
      return moment.utc(d).format("DD-MM-YYYY");
    },
    async init() {
      let payload = {};
      this.getServiceInfo(payload);
      // Testing purpose starts
      this.selectedAdtype = null;
      this.selectedCategory = null;
      this.selectedTitle = "";
      this.selectedProvider = null;
      this.dateRange = {
        startDate: new Date().setMonth(new Date().getMonth() - 3),
        endDate: new Date(),
      };
      this.filterData();
      // Testing purpose ends
      
      
    },
  },
};
</script>
<style scoped>
.showBreadcrumbs {
  display: none !important;
}
.width-90 {
  width: 100%;
  margin: 5px 20px;
}
@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
@media screen and (max-width: 767px) {
  .custom-font-18,
  .custom-font-24 {
    font-size: 16px !important;
  }
}
</style>